var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.painel.ativo)?_c('div',[_c('audio',{attrs:{"src":require("@/assets/audios/alerta1.mp3")}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.chamando),expression:"chamando"}],staticClass:"row"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center p-1",style:('width: 100%; height: 100%; background:' +
          _vm.painel.cor_card +
          '; flex-direction: column;')},[_c('span',{style:('color:' +
            _vm.painel.cor_primaria +
            ';   font-size: 16vh;  line-height: 18vh;')},[_vm._v("Senha")]),_c('br'),_c('span',{style:('color:' +
            _vm.painel.cor_secundaria +
            '; font-size: 28vh; line-height: 20vh;')},[_vm._v(_vm._s(_vm.senhas[0].prefixo)),_c('span',{style:('color:' + _vm.painel.cor_primaria + ';')},[_vm._v(_vm._s(_vm.senhas[0].senha))])]),_c('br'),_c('span',{style:('color:' +
            _vm.painel.cor_primaria +
            ';   font-size: 15vh;  line-height: 17vh;')},[_vm._v("Local")]),_c('br'),_c('span',{style:('color:' +
            _vm.painel.cor_primaria +
            ';   font-size: 13vh;  line-height: 12vh;')},[_vm._v(_vm._s(_vm.senhas[0].local))]),_c('br'),(_vm.senhas[0].pessoa && _vm.painel.mostra_pessoa)?_c('span',{style:('color:' +
            _vm.painel.cor_primaria +
            ';   font-size: 5vh;  line-height: 6vh;')},[_vm._v(_vm._s(_vm.senhas[0].pessoa))]):_vm._e(),_c('br')])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.chamando),expression:"!chamando"}],staticClass:"row"},[_c('div',{staticClass:"col-md-12 px-3 py-2",style:('font-family: helvetica; height: 100vh; background: radial-gradient(' +
          _vm.painel.background +
          ', #000000); display: flex;flex-wrap: wrap;')},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.painel.video),expression:"!painel.video"}],style:('width: 68.5%; height: 84%; background:' +
            _vm.painel.cor_card +
            '; border-radius:' +
            _vm.painel.arredondamento +
            'px; margin-right: 1.5%;')},[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"flex-direction":"column","height":"100%"}},[_c('span',{style:('color:' +
                _vm.painel.cor_primaria +
                ';   font-size: 6vh;  line-height: 6vh;')},[_vm._v("Senha")]),_c('br'),_c('span',{style:('color:' +
                _vm.painel.cor_secundaria +
                '; font-size: 10vh; line-height: 7vh;')},[_vm._v(" "+_vm._s(_vm.senhas[0].prefixo)),_c('span',{style:('color:' + _vm.painel.cor_primaria + ';')},[_vm._v(_vm._s(_vm.senhas[0].senha))])]),_c('br'),_c('span',{style:('color:' +
                _vm.painel.cor_primaria +
                ';   font-size: 6vh;  line-height: 6vh;')},[_vm._v("Local")]),_c('br'),_c('span',{style:('color:' +
                _vm.painel.cor_primaria +
                ';   font-size: 9vh;  line-height: 7vh;')},[_vm._v(_vm._s(_vm.senhas[0].local))]),_c('br'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.painel.mostra_pessoa),expression:"painel.mostra_pessoa"}],staticClass:"text-center",style:('color:' +
                _vm.painel.cor_primaria +
                ';   font-size: 6vh;  line-height: 7vh;')},[_vm._v(" "+_vm._s(_vm.senhas[0].pessoa)+" ")]),_c('br')])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.painel.video),expression:"painel.video"}],style:('width: 68.5%; height: 84%; background: #000000;' +
            'border-radius:' +
            _vm.painel.arredondamento +
            'px; margin-right: 1.5%;')},[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"flex-direction":"column","height":"100%"}},[_c('video',{attrs:{"src":_vm.painel.video_unidade ? _vm.painel.video_unidade.video_link : '',"width":"100%","autoplay":"","muted":"","loop":""},domProps:{"muted":true}})])]),_c('div',{staticClass:"d-flex text-center p-0",style:('width: 30%; height: 84%; font-size: 3.7vh; color: ' +
            _vm.painel.cor_primaria +
            '; background:' +
            _vm.painel.cor_card +
            '; border-radius:' +
            _vm.painel.arredondamento +
            'px; flex-direction: column;')},[_c('div',{staticClass:"p-0 d-flex align-items-center justify-content-center",staticStyle:{"min-height":"7%"}},[_vm._v(" Últimas Chamadas ")]),_vm._l((_vm.senhas),function(senha,index){return _c('div',{key:senha + index + '',staticClass:"d-flex flex-column align-items-center justify-content-center",staticStyle:{"height":"15% !important","border-top":"1px solid #000"}},[_c('div',{style:('color:' +
                _vm.painel.cor_secundaria +
                ';' +
                (senha.pessoa && _vm.painel.mostra_pessoa
                  ? 'font-size: 3vh; line-height: 3vh;'
                  : 'font-size: 3.5vh;'))},[_vm._v(" "+_vm._s(senha.prefixo)),_c('span',{style:('color:' + _vm.painel.cor_primaria)},[_vm._v(_vm._s(senha.senha))])]),_c('div',{style:(senha.pessoa && _vm.painel.mostra_pessoa
                  ? 'font-size: 2.5vh; line-height: 3vh;'
                  : 'font-size: 3.5vh;')},[_vm._v(" "+_vm._s(senha.local)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.painel.mostra_pessoa),expression:"painel.mostra_pessoa"}],style:(senha.pessoa ? 'font-size: 2vh; line-height: 1.5vh;' : '')},[_vm._v(" "+_vm._s(senha.pessoa)+" ")])])})],2),_c('div',{class:_vm.painel.relogio
              ? 'd-flex align-items-center justify-content-center'
              : 'd-none',style:('border-radius:' +
            _vm.painel.arredondamento +
            'px; width: 10%; height: 14%; background: ' +
            _vm.painel.cor_card +
            '; margin-top: 1.5%; flex-direction: column;')},[_c('span',{style:('font-weight: 700; color:' +
              _vm.painel.cor_primaria +
              '; font-size: 2.5vh;')},[_vm._v(_vm._s(_vm.data))]),_c('span',{style:('font-weight: 700; color:' +
              _vm.painel.cor_primaria +
              '; font-size: 2.5vh;')},[_vm._v(_vm._s(_vm.hora))])]),_c('div',{class:_vm.painel.previsao
              ? 'd-flex align-items-center justify-content-center'
              : 'd-none',style:('border-radius:' +
            _vm.painel.arredondamento +
            'px; width: 19%; height: 14%; background: ' +
            _vm.painel.cor_card +
            '; margin-top: 1.5%; margin-left: ' +
            (_vm.painel.relogio ? '1' : '0') +
            '%;')},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.previsao.entidade),expression:"previsao.entidade"}],staticClass:"img-lateral"},[_c('img',{attrs:{"src":_vm.previsao.icone,"id":"icone","alt":"icone","height":"80%"}}),_c('br')]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.previsao.entidade),expression:"previsao.entidade"}],staticClass:"texto"},[_c('div',{attrs:{"id":"tempo"}},[_c('b',[_vm._v(_vm._s(_vm.previsao.entidade)+", "+_vm._s(_vm.previsao.date))])]),_c('img',{staticClass:"ml-1",attrs:{"src":_vm.previsao.temp_min_tende_icone,"alt":"img_temp_min","id":"img_temp_min","height":"20%"}}),_vm._v(" "),_c('span',{attrs:{"id":"min"}},[_vm._v(_vm._s(_vm.previsao.temp_min))]),_c('span',{staticClass:"arial"},[_vm._v("º")]),_c('img',{staticClass:"ml-1",attrs:{"src":_vm.previsao.temp_max_tende_icone,"alt":"img_temp_max","id":"img_temp_max","height":"20%"}}),_vm._v(" "),_c('span',{attrs:{"id":"max"}},[_vm._v(_vm._s(_vm.previsao.temp_max))]),_c('span',{staticClass:"arial"},[_vm._v("º")]),_c('div',{staticClass:"resumo"})])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center",style:('overflow: hidden; border-radius:' +
            _vm.painel.arredondamento +
            'px; width: ' +
            (100 -
              ((_vm.painel.relogio ? 11 : 0) +
                (_vm.painel.previsao ? 20 : 0) +
                (_vm.painel.logo ? 31.5 : 0))) +
            '%; height: 14%; background: ' +
            _vm.painel.cor_card +
            '; margin-top: 1.5%; margin-left: ' +
            (_vm.painel.relogio + _vm.painel.previsao ? '1' : '0') +
            '%;')},[_c('span',{style:('color:' + _vm.painel.cor_primaria + '; font-size: 6vh;'),attrs:{"id":"mensagens"}},[_vm._v(_vm._s(_vm.painel.mensagem))])]),_c('div',{class:_vm.painel.logo
              ? 'd-flex align-items-center justify-content-center'
              : 'd-none',style:('border-radius:' +
            _vm.painel.arredondamento +
            'px; width: 30%; height: 14%; background: ' +
            _vm.painel.cor_card +
            '; margin-top: 1.5%; margin-left: 1.5%;')},[_c('img',{staticStyle:{"max-height":"90%","max-width":"98%"},attrs:{"src":_vm.painel.logo_f}})])])])]):_c('div',{staticClass:"d-flex justify-content-center align-items-center p-1",style:('width: 100vw; height: 100vh; background:' +
      _vm.painel.background +
      '; flex-direction: column;')},[_c('h1',{style:('color:' + _vm.painel.cor_card)},[_vm._v("PAINEL INATIVO")])])])}
var staticRenderFns = []

export { render, staticRenderFns }